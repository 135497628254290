// fonts

// sass-lint:disable-all

// latin-ext
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: local('Oswald Medium'), local('Oswald-Medium'),
    url('#{$font-path}/oswald-medium-webfont.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// latin
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: local('Oswald Medium'), local('Oswald-Medium'),
    url('#{$font-path}/oswald-medium-webfont.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

// latin-ext
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Work Sans'), local('WorkSans-Regular'),
    url('#{$font-path}/worksans-regular-webfont.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// latin
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Work Sans'), local('WorkSans-Regular'),
    url('#{$font-path}/worksans-regular-webfont.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

// latin-ext
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Work Sans Medium'), local('WorkSans-Medium'),
    url('#{$font-path}/worksans-medium-webfont.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// latin
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Work Sans Medium'), local('WorkSans-Medium'),
    url('#{$font-path}/worksans-medium-webfont.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

// latin-ext
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
    url('#{$font-path}/worksans-semibold-webfont.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// latin
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
    url('#{$font-path}/worksans-semibold-webfont.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

// latin-ext
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Work Sans Bold'), local('WorkSans-Bold'),
    url('#{$font-path}/worksans-bold-webfont.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// latin
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Work Sans Bold'), local('WorkSans-Bold'),
    url('#{$font-path}/worksans-bold-webfont.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

// ana-icons
@font-face {
  font-family: 'ana-fonts';
  src: url('#{$font-path}/ana-fonts.eot?akhglg');
  src: url('#{$font-path}/ana-fonts.eot?akhglg#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/ana-fonts.ttf?akhglg') format('truetype'),
    url('#{$font-path}/ana-fonts.woff?akhglg') format('woff'),
    url('#{$font-path}/ana-fonts.svg?akhglg#ana-fonts') format('svg');
  font-weight: normal;
  font-style: normal;
}

.ana {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ana-fonts' !important;
  speak: none;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.ana-ship:before {
  content: '\e900';
}

.ana-store:before {
  content: '\e90b';
}
