// pagination

.mod-pagination {
  padding-left: 0;
  padding-right: 0;
  
  ul {
    text-align: right;
    margin-left: 0;
    margin-bottom: 1rem;
    
    &:before,
    &:after {
      display: table;
      content: ' ';
      -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
    }
    
    &:after {
      clear: both;
    }
    
    > li {
      line-height: 1;
      display: none;
      
      @media print, screen and (min-width: 40em) {
        display: inline-block;
      }
      
    }
    
  }
  
  a {
    font-size: rem(14);
    font-weight: $bold;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 2px;
    
    @media print, screen and (min-width: 40em) {
      font-size: rem(16);
    }
    
  }
  
  .active {
    border-bottom: 2px solid $torea-bay-blue;
    
    a {
      padding-bottom: 0;
    }
    
  }
  
  .next,
  .previous {
    display: inline-block;
    
    i {
      font-size: rem(14);
    }
    
  }
  
}
