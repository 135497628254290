#root {
  min-height: 2500px;
}

.loader {
  border: 10px solid $alto-gray;
  border-top-color: $mariner-blue;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
  z-index: 1;
  margin: -75px auto;
  
  @include md-up {
    top: 20%;
  }
  
  @include lg-up {
    top: 15%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
