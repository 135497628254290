// inside block list module

.mod-inside-block-list {

  p {
    word-break: break-word;
  }

  .mod-header,
  li {
    border: 1px solid $dusty-gray;
  }

  .mod-header {
    padding: 15px 15px 40px;

    @include md-up {
      padding: 20px 20px 40px;
    }

    h3 {
      margin-bottom: rem(15);
    }
  }

  .mod-content {
    margin-top: -20px;
    padding: 0 10px;

    @include md-up {
      padding: 0 20px;
    }
  }

  .mod-header p:last-child,
  .mod-content .list > li p:last-child {
    margin-bottom: 0;
  }

  .btn {
    font-size: rem(16);
    text-align: center;
    position: relative;
    float: right;
    padding-top: 10px;
    padding-bottom: 26px;
    width: 100%;

    @include md-up {
      font-size: rem(18);
    }

    @include lg-up {
      font-size: rem(21);
    }

    &:after {
      background: $off-white;
      content: '';
      display: table;
      height: 30px;
      position: absolute;
      right: rem(-50);
      top: 49px;
      width: 150%;
      @include rotate(1.5);

      @include md-up {
        right: rem(-15);
        height: 36px;
        width: 480px;
      }

      @include lg-up {
        top: 57px;
        width: 520px;
      }
    }
  }

  li {
    margin-bottom: 15px;

    a {
      @include md-up {
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
    .text-wrapper {
      padding: 15px 8px 15px 20px;
    }

    .btn-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 50px;
      width: 100%;

      @include md-up {
        max-width: 136px;
      }

      i {
        margin-right: rem(5);
      }

      span {
        font-weight: $bold;
      }
    }

    &:hover {
      border-color: $aquamarine-blue
    }

    h4 {

      @include md-up {
        font-size: rem(21);
      }
    }
  }
}

.medium-6 .mod-inside-block-list {

  .btn {

    @include md-up {
      font-size: rem(16);
    }

    @include lg-up {
      font-size: rem(21);
    }
  }

  .mod-header h3 {

    @include md-up {
      font-size: rem(24);
    }

    @include lg-up {
      font-size: rem(30);
    }
  }

  p {

    @include md-up {
      font-size: rem(14);
    }

    @include lg-up {
      font-size: rem(16);
    }
  }

}