.sra-forms-container {
  counter-reset: section;

  #root__description {
    // sass-lint:disable-line no-ids
    font-size: 1.875rem;
    font-family: $oswald;
    text-transform: uppercase;
  }

  .error-detail {
    color: #e44;
  }

  .error-detail,
  .field {
    margin-bottom: 1rem;
  }

  input[type='file'] {
    width: auto;
  }

  .file-info,
  .error-detail {
    margin: 0;
    padding: 0;

    li {
      display: block;
    }
  }

  .field:not(.field-object) > .field-internal {

    &.field-error .form-control {
      border: 1px solid #e44;
    }

    .control-label {
      user-select: none;
    }
  }

  fieldset fieldset {
    background: $white;
    border: 1px solid $alto-gray;
    margin: 1rem 0;
    padding: 1rem;

    legend {
      font-weight: 700;
      margin-bottom: rem(-2);
      padding-bottom: rem(2);

      &::before {
        background: $curious-blue;
        color: $white;
        content: counter(section);
        counter-increment: section;
        display: inline-block;
        font-weight: 700;
        margin-right: rem(8);
        width: 1.5rem;
        text-align: center;
      }
    }

    .field-description,
    .form-group.field {
      margin-left: auto;
      margin-right: auto;
      max-width: 800px;
    }
  }
}
