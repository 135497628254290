// content container module

.mod-content-container {
  h3,
  p {
    margin-bottom: 0;
  }

  h3 {
    @include md-up {
      font-size: rem(26);
    }
  }

  span {
    font-weight: $bold;
  }

  .mod-header {
    padding: rem(15);

    @include md-up {
      padding: rem(24) rem(20);
    }
  }

  .mod-content {
    border: 1px solid $dusty-gray;
    border-top: 0;
    padding: 10px 15px;

    @include md-up {
      padding: rem(15) rem(15) rem(20);
    }
  }

  select {
    margin-top: rem(10);
    margin-bottom: 0;
  }
}

.cell.large-6 .mod-content-container .list {
  @include lg-up {
    display: inline-block;
    width: 49%;
    vertical-align: top;
  }
}
