// callout and announcement modules

.mod-callout {

  p {
    margin-bottom: 0;

    @include md-up {
      font-size: rem(18);
    }
  }

  .callout-left,
  .callout-right {

    @include lg-up {
      width: 50%;
    }
  }

  .list {

    @include lg-up {
      display: flex;
      align-content: flex-end;
    }
  }

  .callout-left {
    align-self: flex-start;
    border: 1px solid $dusty-gray;
    border-right: 0;
    height: fit-content;
    margin-top: rem(30);
    padding: 22px 0 22px 30px;
    width: 50%;

    h2 {
      margin-bottom: 0;
    }
  }

  .callout-right {
    overflow: hidden;
    padding: 12px 15px 30px;
    position: relative;

    @include md-up {
      padding: 18px 0 50px 28px;
    }

    @include lg-up {
      padding-top: 20px;
      padding-bottom: 42px;
    }

    h2 {
      margin-bottom: 5px;
    }

    &:after {
      background: $off-white;
      bottom: -20px;
      content: '';
      display: table;
      height: 31px;
      position: absolute;
      right: rem(-50);
      width: 150%;
      @include rotate(2);

      @include md-up {
        height: 38px;
      }

      @include lg-up {
        height: 33px;
      }
    }
  }
}

.mod-announcement {
  border: 1px solid $mariner-blue;
  border-left: 0;
  border-right: 0;

  p {
    margin-bottom: 0;
  }

  .mod-content {

    > a {
      display: block;
      text-align: center;
    }

    span {
      display: inline-block;
      font-weight: $bold;
    }
  }
}

.mod-aside-callout {
  position: relative;

  .mod-header h3,
  .mod-content p:last-child {
    margin-bottom: 0;
  }

  .mod-header {
    position: absolute;
    max-width: 240px;
    padding: rem(10) rem(15);
    top: -25px;
    width: 100%;

    @include md-up {
      max-width: 280px;
      padding: rem(24) rem(20);
      top: -54px;
    }
  }

  .mod-content {
    border: 1px solid $dusty-gray;
    margin-top: rem(25);
    margin-left: rem(20);
    padding: rem(40) rem(20) rem(17);

    @include md-up {
      margin-top: rem(54);
      padding: rem(58) rem(30) rem(25);
    }

    a {
      display: inline-block;
      font-weight: $bold;
    }
  }
}

.medium-6 .mod-aside-callout {

  .mod-header {

    @include md-up {
      max-width: 240px;
      padding: rem(10) rem(15);
      top: -25px;
    }

    @include lg-up {
      max-width: 280px;
      padding: rem(24) rem(20);
      top: -54px;
    }
  }

  .mod-header h3 {

    @include md-up {
      font-size: rem(24);
    }

    @include lg-up {
      font-size: rem(30);
    }
  }

  .mod-content {

    @include md-up {
      margin-top: rem(25);
      padding: rem(40) rem(20) rem(17);
    }

    @include lg-up {
      margin-top: rem(54);
      padding: rem(58) rem(30) rem(25);
    }
  }

  p {

    @include md-up {
      font-size: rem(14);
    }

    @include lg-up {
      font-size: rem(16);
    }
  }
}