// styled text module

.mod-styled-text {
  border: 1px solid $black;
  border-left: 0;
  border-right: 0;
  padding: 15px 0;
  
  @include md-up {
    padding: 20px 0 25px;
  }
  
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: rem(15);
  }
  
  p:last-child {
    margin-bottom: 0;
  }
}