// careers module

.mod-careers {
  background-image: url('../images/dist-news-back.svg');
  background-size: 1000px;
  background-position-y: -345px;
  background-position-x: -440px;

  @include md-up {
    background-position-y: -335px;
    background-position-x: -365px;
  }

  @include lg-up {
    background-position-x: -315px;
  }

  .mod-header {
    @include md-up {
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      justify-content: space-between;
    }

    select {
      margin-bottom: 0;

      @include md-up {
        max-width: 330px;
      }
    }
  }

  .list.media > li {
    @include md-up {
      display: block;
      float: left;
      height: auto;
      width: 50%;
    }

    @include lg-up {
      padding-left: rem(8);
      padding-right: rem(8);
      margin-bottom: rem(16);
      width: 33.333333%;
    }

    &:nth-of-type(n) {
      @include md-up {
        clear: none;
      }
    }

    &:nth-of-type(2n + 1) {
      @include md-up {
        clear: none;
      }
    }

    &:nth-of-type(3n + 1) {
      @include lg-up {
        clear: both;
      }
    }
  }

  &.mod-article-list .mod-content {
    padding-left: 0;
  }

  &.mod-media-grid .mod-content {
    @include lg-up {
      margin-left: rem(-8);
      margin-right: rem(-8);
    }
  }

  .card-section {
    margin-bottom: rem(10);
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1 1 auto;
    height: 100%;

    p {
      height: 100%;
      display: inline-block;
    }
  }

  .card {
    padding: rem(10);
    min-height: 300px;
    max-height: 300px;
    height: 300px;
    text-align: left;
    display: flex;
    flex-direction: column;

    @include lg-up {
      padding: 15px;
      max-width: 360px;
    }

    h5 {
      font-weight: $bold;
      font-size: rem(14);
      margin-bottom: rem(20);

      @include lg-up {
        font-size: rem(16);
      }
    }

    p,
    i {
      font-size: rem(13);
      margin-bottom: 0;

      @include lg-up {
        font-size: rem(14);
      }
    }

    header {
      margin-bottom: rem(14);
      flex: 0 0 auto;

      @include md-up {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
    }

    .image-wrapper {
      display: none;
      max-width: 105px;

      @include md-up {
        display: block;
      }

      figure {
        min-width: 105px;
        min-height: 80px;
      }
    }

    .text-wrapper {
      @include md-up {
        margin-right: rem(10);
      }
    }
  }
}
