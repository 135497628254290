// lists

.list {
  list-style: none;
  
  &.floated > li {
    
    @include sm-up {
      float: left;
    }
  }
  
  &.articles {
    
    .text-wrapper,
    .image-wrapper,
    .headline-wrapper {
      float: left;
    }
  }
  
  &.media {
    text-align: center;
    
    .crop-photo {
      background-size: contain;
    }
    
    > li {
      
      @include sm-up {
        display: block;
        float: left;
        height: auto;
        width: 50%;
      }
      
      &:nth-of-type(n) {
        
        @include sm-up {
          clear: none;
        }
      }
      
      &:nth-of-type(2n+1) {
        
        @include sm-up {
          clear: both;
        }
        
        @include md-up {
          clear: none;
        }
        
        @include lg-up {
          clear: none;
        }
      }
    }
    
    &.medium-block-3 > li {
      
      @include md-up {
        width: 33.333333%;
      }
      
      &:nth-of-type(3n+1) {
        
        @include md-up {
          clear: both;
        }
        
        @include lg-up {
          clear: none;
        }
      }
    }
    
    &.large-block-4 > li {
      
      @include lg-up {
        width: 25%;
      }
      
      &:nth-of-type(4n+1) {
        
        @include lg-up {
          clear: both;
        }
      }
    }
    
    &.large-block-6 > li {
      
      @include lg-up {
        width: 16.666666%;
      }
      
      &:nth-of-type(6n+1) {
        
        @include lg-up {
          clear: both;
        }
      }
    }
  }
}