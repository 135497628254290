// product container module

.mod-product-container {
  position: relative;
  border: 1px solid $dusty-gray;

  .mod-content {
    @include lg-up {
      display: flex;
      justify-content: space-between;
    }
  }

  .product-image-wrapper {
    overflow: hidden;
    position: relative;

    @include lg-up {
      max-width: 382px;
      width: 382px;
    }

    p {
      padding-left: rem(20);

      @include lg-up {
        padding-left: rem(30);
      }
    }
  }

  .image-wrapper {
    padding: rem(32) rem(20) rem(0);
    margin-bottom: rem(20);

    @include lg-up {
      padding: rem(32) rem(0) rem(0) rem(30);
    }
  }

  .crop-photo {
    background-size: contain;
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
    max-height: 400px;
    z-index: 2;

    @include lg-up {
      margin-right: 0;
      margin-left: 0;
      max-width: 300px;
      max-height: 300px;
    }
  }

  .solid-iceberg {
    left: -100px;
    top: -233px;
    position: absolute;
    transform: scale(1);
    transition: top 0.3s ease-in-out, left 0.3s ease-in-out;

    @include md-up {
      left: -20px;
      top: -135px;
    }

    @include lg-up {
      left: -272px;
      top: -155px;
    }
  }

  .list {
    border: 1px solid $alto-gray;
    border-left: 0;
    border-right: 0;
    padding: rem(10);
    margin-bottom: rem(10);

    @include lg-up {
      padding: rem(15) rem(0) rem(15) rem(20);
      margin-bottom: rem(20);
    }

    > li {
      padding: 0 rem(10);
    }
  }

  .text-wrapper {
    padding: rem(15) 0;

    @include lg-up {
      margin-left: rem(60);
      padding: rem(22) 0 rem(30);
      width: 60%;
    }

    h1 {
      margin-bottom: rem(15);
      padding-left: rem(20);

      @include md-up {
        margin-bottom: rem(22);
        font-size: rem(30);
      }
    }

    p {
      padding: 0 rem(15);

      @include lg-up {
        padding-left: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn-wrapper {
      border: 1px solid $alto-gray;
      border-left: 0;
      border-right: 0;
      padding: rem(10) rem(20);
      margin-bottom: rem(20);

      @include md-up {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .btn {
        width: 100%;
        padding: rem(20) 0;
        text-align: center;
        cursor: default;

        @include md-up {
          width: 205px;
        }

        &:hover {
          background-color: $gun-powder-gray-blue;
        }

        &.status {
          background-color: $alto-gray;
          color: $gun-powder-gray-blue;
        }
      }

      h5 {
        font-size: rem(14);
        margin-bottom: 0;
        margin-top: rem(20);
        text-align: center;

        @include md-up {
          margin-top: rem(0);
          text-align: right;
          font-size: rem(16);
        }
      }
    }
  }
}
