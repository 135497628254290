// studio news list module

.studio.mod-article-list {
  background-image: url('../images/studio-news-back.svg');
  
  ul {
    border: 1px solid $alto-gray;
    
    > li {
      
      > a {
        padding: 10px;
        display: block;
        
        @include sm-up {
          padding: rem(15);
        }
      }
      
      &:after {
        content: '';
        display: table;
        background: $alto-gray;
        height: 1px;
        width: 90%;
        float: right;
        
        @include sm-up {
          width: 91%;
        }
      }
      
      &:last-child:after {
        display: none;
      }
    }
  }
  
  .image-wrapper {
    width: 15%;
    max-width: 35px;
    max-height: 34px;
    
    @include sm-up {
      max-height: 45px;
      max-width: 46px;
      width: 12%;
    }
  }
  
  .headline-wrapper {
    width: 85%;
    padding-left: 10px;
    
    @include sm-up {
      width: 88%;
    }
    
    .text-wrapper {
      width: 80%;
      
      @include sm-up {
        width: 82%;
      }
    }
    
    .date-wrapper {
      width: 20%;
      padding: 8px 0;
      text-align: right;
      float: right;
      
      @include sm-up {
        width: 12%;
      }
    }
    
    h4 {
      margin-bottom: 0;
    }
    
    time {
      font-weight: $bold;
      font-size: rem(14);
      
      @include sm-up {
        font-size: rem(16);
      }
    }
  }
}

.medium-6 .studio.mod-article-list {
  
  ul > li {
    
    > a {
      
      @include md-up {
        padding: rem(10);
      }
      
      @include lg-up {
        padding: rem(15);
      }
    }
    
    &:after {
      
      @include md-up {
        width: 88%;
      }
      
      @include lg-up {
        width: 91%;
      }
    }
  }
  
  .image-wrapper {
    
    @include md-up {
      max-height: 34px;
      max-width: 35px;
      width: 15%;
    }
    
    @include lg-up {
      max-height: 45px;
      max-width: 46px;
      width: 10%;
    }
  }
  
  .headline-wrapper {
    
    @include md-up {
      width: 85%;
    }
    
    @include lg-up {
      width: 90%;
    }
    
    .text-wrapper {
      
      @include md-up {
        width: 80%;
      }
      
      @include lg-up {
        width: 88%;
      }
    }
    
    .date-wrapper {
      
      @include md-up {
        width: 20%;
      }
      
      @include lg-up {
        width: 12%;
      }
    }
    
    time {
      
      @include md-up {
        font-size: rem(14);
      }
      
      @include lg-up {
        font-size: rem(16);
      }
    }
  }
}
