@mixin rotate($deg) {
  transform: rotate($deg + deg);
}

@mixin skew($deg) {
  transform: skew($deg + deg);
}

@mixin two-color-linear-gradient($direction, $start-color, $end-color) {
  background: $start-color;

  @if $direction == left {
    $direction: right;
  } @else {
    $direction: left;
  }

  //sass-lint:disable no-duplicate-properties
  background: linear-gradient(to $direction, $start-color 0%, $end-color 100%);
}

// transition animation
@mixin transition($type, $time, $easing) {
  transition: $type ($time + s) $easing;
}

// media queries
@mixin sm-up {
  @media only #{$small-min-width} {
    @content;
  }
}

@mixin md-up {
  @media only #{$medium-min-width} {
    @content;
  }
}

@mixin lg-up {
  @media only #{$large-min-width} {
    @content;
  }
}
