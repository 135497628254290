// article list mariner blue iceberg background module

.article-list-mbibg.mod-article-list {
  background-image: url('../images/dist-news-back.svg');
  position: relative;
  z-index: 9;

  .mod-content {

    ul > li {
      border: 1px solid $alto-gray;
      overflow: hidden;
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid $alto-gray;
      }

      &:hover {
        border-color: $aquamarine-blue;

        & + li {
          border-top-color: $aquamarine-blue;
        }
      }
    }
  }

  .headline-wrapper,
  .image-wrapper,
  .btn-wrapper {
    height: 100px;
    max-height: 100px;

    @include sm-up {
      height: 95px;
      max-height: 95px;
    }
  }

  .list.articles .btn-wrapper,
  .list.articles .image-wrapper {
    float: right;
  }

  .btn-wrapper,
  .image-wrapper {
    width: 40%;
    max-width: 140px;

    @include sm-up {
      width: 30%;
    }
  }

  .btn-wrapper {
    background-color: $fountain-blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    label {
      font-size: rem(16);
      font-weight: $bold;
      margin-bottom: 0;

      @include md-up {
        font-size: rem(18);
      }
    }

    i {
      font-size: rem(32);

      @include md-up {
        font-size: rem(36);
      }
    }
  }

  .headline-wrapper {
    width: 60%;
    padding: 5px 10px;
    line-height: .5;
    overflow: hidden;

    time {
      font-size: rem(10);

      @include md-up {
        font-size: rem(11);
      }
    }

    h4 {
      margin-top: 5px;
      margin-bottom: 4px;
      max-height: 36px;

      @include sm-up {
        margin-bottom: 0;
      }

      @include md-up {
        font-size: rem(16);
        max-height: 20px;
      }
    }

    &.press-releases h4 {

      @include md-up {
        max-height: none;
      }
    }

    p {
      max-height: 36px;

      @include sm-up {
        max-height: 45px;
      }

      @include md-up {
        font-size: rem(14);
      }
    }

    p,
    h4 {
      overflow: hidden;
    }

    @include sm-up {
      padding: 10px 15px;
      width: 70%;
    }
  }

  footer {
    margin-top: 10px;
  }

  .btn {
    font-size: rem(18);
    text-align: right;
    position: relative;
    float: right;
    padding-top: 10px;
    padding-bottom: 25px;
    padding-right: 27px;
    width: 100%;
    max-width: 270px;

    @include sm-up {
      font-size: rem(21);
      max-width: 366px;
    }

    &:after {
      background: $off-white;
      content: '';
      display: table;
      height: 30px;
      position: absolute;
      right: rem(-80);
      top: 49px;
      width: 150%;
      @include rotate(3);

      @include md-up {
        right: rem(-20);
        top: 51px;
        width: 310px;
      }

      @include lg-up {
        width: 400px;
      }
    }
  }
}

.medium-6 .article-list-mbibg.mod-article-list {

  .headline-wrapper,
  .image-wrapper,
  .btn-wrapper {

    @include md-up {
      height: 100px;
      max-height: 100px;
    }

    @include lg-up {
      height: 95px;
      max-height: 95px;
    }
  }

  .btn-wrapper,
  .image-wrapper {

    @include md-up {
      width: 40%;
    }

    @include lg-up {
      width: 30%;
    }
  }

  .btn-wrapper {

    label {
      @include md-up {
        font-size: rem(16);
      }

      @include lg-up {
        font-size: rem(18);
      }
    }

    i {
      @include md-up {
        font-size: rem(32);
      }

      @include lg-up {
        font-size: rem(36);
      }
    }
  }

  .headline-wrapper {

    @include md-up {
      padding: rem(5) rem(10);
      width: 60%;
    }

    @include lg-up {
      padding: rem(10) rem(15);
      width: 70%;
    }

    time {

      @include md-up {
        font-size: rem(10);
      }

      @include lg-up {
        font-size: rem(11);
      }
    }

    h4 {

      @include md-up {
        font-size: rem(14);
        margin-bottom: rem(4);
        max-height: 36px;
      }

      @include lg-up {
        font-size: rem(16);
        margin-bottom: 0;
        max-height: 20px;
      }
    }

    &.press-releases h4 {

      @include lg-up {
        max-height: none;
      }
    }

    p {

      @include md-up {
        font-size: rem(12);
        max-height: 40px;
      }

      @include lg-up {
        font-size: rem(14);
        max-height: 45px;
      }
    }
  }

  .btn {

    @include md-up {
      font-size: rem(18);
      max-width: 270px;
    }

    @include lg-up {
      font-size: rem(21);
      max-width: 366px;
    }

    &:after {

      @include md-up {
        top: 49px;
      }

      @include lg-up {
        top: 51px;
      }
    }
  }
}
