// utilities

.right,
.float-right {
  float: right;
}

.left,
.float-left {
  float: left;
}

.padding {
  padding: 10px;
}
