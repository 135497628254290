// customizable carousel module

.mod-carousel {
  .owl-carousel,
  .owl-carousel .owl-item {
    overflow: hidden;
  }

  .owl-carousel {
    max-height: 322px;
  }
}
