// layered banner image module

.mod-layered-banner-image {
  position: relative;
  overflow: hidden;

  h2 {
    margin-bottom: rem(5);
    margin-top: 0;

    @include md-up {
      font-size: rem(32);
    }
  }

  p {
    font-weight: $medium;
    font-size: rem(12);

    @include md-up {
      font-size: rem(16);
    }
  }

  .mobile-background {
    @include md-up {
      display: none;
    }
  }

  .desktop-background {
    display: none;

    @include md-up {
      display: block;
    }
  }

  .crop-photo img {
    height: 222px;

    @include md-up {
      height: 322px;
    }
  }

  .iceberg,
  .text-wrapper {
    position: absolute;
  }

  .text-wrapper {
    left: rem(12);
    max-width: 220px;
    top: rem(10);

    @include sm-up {
      max-width: 265px;
    }

    @include md-up {
      left: rem(22);
      max-width: 350px;
      top: rem(20);
    }

    @include lg-up {
      left: rem(32);
      top: rem(30);
    }

    > p:nth-child(2) {
      @include md-up {
        margin-bottom: rem(25);
      }
    }

    > p:last-child {
      @include md-up {
        margin-bottom: 0;
      }
    }
  }

  .iceberg {
    color: rgba(2, 39, 183, 0.66);
    left: -370px;
    top: -184px;
    transition: top 0.3s ease-in-out, left 0.3s ease-in-out;

    @include sm-up {
      top: -204px;
      left: -312px;
    }

    @include md-up {
      left: -123px;
      top: -176px;
    }

    @include lg-up {
      left: -114px;
    }

    > svg {
      transform: scale(1);
    }
  }
}
