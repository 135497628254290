// base styles

// global

// fonts
body,
p,
a,
span,
input,
h4,
h5,
h6,
.blog-header h1,
.blog-content h1,
.blog-content h2,
.blog-content h3,
.mod-aside-callout .mod-content h1,
.mod-aside-callout .mod-content h2,
.mod-aside-callout .mod-content h3,
.mod-inside-block-list .mod-content h1,
.mod-inside-block-list .mod-content h2,
.mod-inside-block-list .mod-content h3 {
  font-family: $work-sans;
}

h1,
h2,
h3,
.mod-styled-text h4,
.mod-styled-text h5,
.mod-styled-text h6,
.mod-inside-block-list .mod-header h4,
.mod-inside-block-list .mod-header h5,
.mod-inside-block-list .mod-header h6 {
  font-family: $oswald;
  font-weight: $medium;
}

.uppercase,
.btn,
a.btn,
button,
input[type='submit'],
.button,
.mod-callout .mod-content h2,
.mod-callout .mod-content p,
.mod-aside-callout .mod-header h3,
.mod-announcement .mod-content span,
.mod-announcement .mod-content h3,
.article-list-mbibg.mod-article-list .mod-header h3,
.studio.mod-article-list .mod-header h3,
.mod-search-header h1,
.mod-layered-banner-image h2,
.mod-careers .mod-header h3,
.mod-careers .card h5,
.mod-styled-text h1,
.mod-styled-text h2,
.mod-styled-text h3,
.mod-styled-text h4,
.mod-styled-text h5,
.mod-styled-text h6,
.mod-inside-block-list h1,
.mod-inside-block-list h2,
.mod-inside-block-list h3,
.mod-inside-block-list h4,
.mod-inside-block-list h5,
.mod-inside-block-list h6,
.mod-inside-block-list a .btn-wrapper,
.reprint,
.release-date,
.mod-content-container h3,
.mod-related-products h3,
.tabs-categories h1,
.tabs-categories h2,
.tabs-categories h3,
.tabs-categories h4 {
  text-transform: uppercase;
}

.semibold,
a.btn,
.btn,
button,
input[type='submit'],
.button,
.mod-inside-block-list .list > li h4,
.mod-related-products h4 {
  font-weight: $semibold;
}

.h2,
h2,
.h3,
h3 {
  font-size: rem(24);

  @include md-up {
    font-size: rem(30);
  }
}

.btn,
.grid-container li,
.grid-container p,
.grid-container span,
.grid-container td,
.grid-container th {
  font-size: rem(14);

  @include md-up {
    font-size: rem(16);
  }
}

address {
  font-style: normal;
}

// transitions

a,
a:hover {
  @include transition(color, 0.3, ease-in-out);
}

.footer a,
a.card {
  @include transition(border, 0.3, ease-in-out);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
span,
img,
time,
*:after,
button,
input,
select,
input[type='text'],
label,
.btn,
.btn:hover,
a.btn,
a.btn:hover,
.button,
.button:hover,
input[type='submit'],
a.login-btn,
a.login-btn:hover,
.hamburger-button,
.image-wrapper,
.mod-search-header .mod-search-header-right [type='radio']:checked label:after,
.mod-search-header .mod-search-header-right [type='radio']:not(:checked) label:after,
.btn-wrapper {
  @include transition(all, 0.3, ease-in-out);
}

.bxslider img,
.bxslider ul,
.bxslider li,
.bx-wrapper .bx-prev,
.bx-wrapper .bx-next {
  transition: none;
}

// colors

html,
body {
  background-color: $off-white;
  height: 100%;
}

.atlantis-green {
  color: $atlantis-green;
}

.atlantis-green-bg,
.tooltip.store-description h6 {
  background-color: $atlantis-green;
}

.buttercup-orange {
  color: $buttercup-orange;
}

.buttercup-orange-bg,
.tooltip.boat-description h6 {
  background-color: $buttercup-orange;
}

.red-violet {
  color: $red-violet;
}

.red-violet-bg,
.tooltip.printer-description h6 {
  background-color: $red-violet;
}

.alizarin-crimson {
  color: $alizarin-crimson;
}

.alizarin-crimson-bg,
.tooltip.reprint-description h6 {
  background-color: $alizarin-crimson;
}

body,
a p,
h1,
h2,
h3,
h4,
h5,
h6,
label,
tbody td:before,
nav .menu .active > a,
.mod-pagination .active a,
.studio.mod-article-list .headline-wrapper time,
.mod-toggle-collapse.faq > a,
.mod-toggle-collapse.faq > a:hover,
.mod-toggle-collapse.faq > a:focus {
  color: $black;
}

.dusty-gray,
nav a,
nav a:active,
nav a:visited,
nav a:focus,
a.login-btn,
a.login-btn:active,
a.login-btn:visited,
a.login-btn:focus,
.article-list-mbibg.mod-article-list .headline-wrapper time,
.studio.mod-article-list .headline-wrapper p,
.mod-search-header .search-bar-container:after,
.mod-search-header .search-bar-container input::placeholder {
  color: $dusty-gray;
}

.dusty-gray-bg,
.tooltip.development-description h6 {
  background-color: $dusty-gray;
}

nav a:hover,
nav .menu .active > a:hover,
nav .dropdown.menu .is-active > a,
.mobile-nav .dropdown-link.dropdown-active {
  color: $torea-bay-blue;
}

.btn,
a.btn,
a.btn:active,
a.btn:focus,
a.btn:visited,
button,
input[type='submit'],
.button,
.page-footer,
.callout-right,
.mod-inside-block-list .btn-wrapper {
  background-color: $gun-powder-gray-blue;
}

.mariner-blue,
.mod-article-list li:hover .headline-wrapper time,
.mod-article-list li:hover .headline-wrapper h4,
.mod-article-list li:hover .headline-wrapper p,
a.card:hover h4,
a.card:hover p,
.solid-iceberg,
.mod-inside-block-list li:hover .text-wrapper h4,
.tabs-title:hover > a,
.tabs-title.is-active > a,
.tabs-title > a[aria-selected='true'],
.tabs-categories li:hover h2,
.tabs-categories li.is-active h2,
.tabs-categories li:hover p,
.tabs-categories li.is-active p {
  color: $mariner-blue;
}

.mod-aside-callout .mod-header,
.mod-content-container .mod-header,
.mod-related-products .mod-header {
  background-color: $mariner-blue;
}

.blog-content a,
article a,
article a:active,
article a:visited,
article a:focus,
.grid-container p a,
.grid-container p a:visited,
.grid-container p a:active,
.grid-container p a:focus,
.mod-styled-text p a,
.mod-styled-text p a:active,
.mod-styled-text p a:visited,
.mod-styled-text p a:focus,
.mod-aside-callout a,
.mod-announcement .mod-content span,
a.single-link {
  color: $fountain-blue;
}

.grid-container p a:hover,
.aquamarine-blue,
.mod-styled-text a:hover,
.blog-content a:hover,
.mod-aside-callout .mod-content a:hover,
.mod-announcement .mod-content a:hover span,
a.single-link:hover,
article a:hover,
a.login-btn:hover {
  color: $aquamarine-blue;
}

.aquamarine-blue-bg,
.btn:hover,
a.btn:hover,
a.login-btn:hover:after,
button:hover,
input[type='submit']:hover,
.button:hover,
.mod-inside-block-list li:hover .btn-wrapper,
.article-list-mbibg.mod-article-list li:hover .btn-wrapper,
.tooltip.shipping-description h6 {
  background-color: $aquamarine-blue;
}

.text-white,
.tooltip h6,
.btn,
.btn:hover,
a.btn,
a.btn:hover,
p a.btn,
p a.btn:hover,
p a.btn:focus,
p a.btn:visited,
p a.btn:active,
.mod-styled-text p a.btn,
.mod-styled-text p a.btn:hover,
.mod-styled-text p a.btn:focus,
.mod-styled-text p a.btn:visited,
.mod-styled-text p a.btn:active,
button,
input[type='submit'],
.button,
.button:hover,
article .btn,
article a.btn,
article a.btn:hover,
.mod-styled-text a.btn,
.mod-styled-text a.btn:hover,
select,
.footer,
.footer a,
.footer a:hover,
.footer a:active,
.footer a:focus,
.footer a:visited,
.mod-callout .mod-content .callout-right,
.mod-callout .mod-content .callout-right h2,
.mod-callout .mod-content p,
.article-list-mbibg.mod-article-list .mod-header h3,
.studio.mod-article-list .mod-header h3,
.mod-search-header h1,
.mod-careers .mod-header h3,
.mod-layered-banner-image .text-wrapper h2,
.mod-layered-banner-image .text-wrapper p,
.mod-content-container .mod-header h3,
.btn-wrapper,
.btn-wrapper label,
.mod-related-products h3,
.mod-aside-callout h3 {
  color: $white;
}

.card,
a.login-btn,
.mobile-nav .nested,
.dropdown.menu > li > ul,
.dropdown.menu .is-active > a,
.mod-styled-text,
.mod-callout .callout-left,
.mod-aside-callout .mod-content,
.mod-article-list .mod-content ul > li > a,
.mod-search-header .mod-search-header-right fieldset,
.mod-search-header .mod-search-header-right [type='radio']:checked label:before,
.mod-search-header .mod-search-header-right [type='radio']:not(:checked) label:before,
.mod-product-container,
.mod-inside-block-list .mod-header,
.mod-inside-block-list .mod-content .list > li,
.mod-content-container .mod-content,
.mod-related-products .mod-content,
.tabs-title {
  background-color: $white;
}

// spacing and sizing

.list.media,
.article-list-mbibg.mod-article-list footer:after,
.list.articles > li > a:after,
.list.articles > li:after,
.list.floated:after,
.blog-header:after,
.icon-wrapper:after {
  clear: both;
  content: '';
  display: table;
}

hr,
figure,
ul.list,
.image-wrapper {
  margin: 0;
  padding: 0;
}

.list.media,
.list.media .image-wrapper,
.cell.center-cell,
.mod-styled-text > *,
.mod-freelance-text .mod-header .text-wrapper,
.mod-freelance-text .mod-content .list {
  margin-left: auto;
  margin-right: auto;
}

nav,
.grid-container,
.mod-styled-text > *,
.mod-announcement .mod-content .text-wrapper,
.mod-freelance-text .mod-header .text-wrapper,
.mod-freelance-text .mod-content .list {
  max-width: 1110px;
  padding-left: rem(20);
  padding-right: rem(20);

  @media print, screen and (min-width: 1110px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.list.articles > li > a,
.studio-logos.mod-media-grid .list.media > li > a {
  display: block;
}

main {
  min-height: 100vh;
  padding-bottom: rem(121.6);
  position: relative;

  @include md-up {
    padding-bottom: rem(68.8);
  }
}

.grid-container.full {
  overflow-x: hidden;
}

main > .grid-container:nth-last-child(2),
.mod-styled-text,
.mod-layered-banner-image,
.mod-callout,
.mod-aside-callout,
.mod-article-list,
.mod-media-grid,
.mod-freelance-text,
.mod-product-container,
.mod-content-container,
.mod-inside-block-list,
.mod-related-products,
.mod-contact-form {
  margin-bottom: rem(35);
}

.mod-careers,
.article-list-mbibg.mod-article-list,
.studio.mod-article-list {
  background-repeat: no-repeat;
  background-size: 1900px;
  background-position-y: -682px;
  background-position-x: 63.5%;
}

.mod-freelance-text,
.mod-styled-text {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.has-tip {
  border-bottom: 0;
  font-weight: $regular;
}

// end global

// images
[class*='crop-'] {
  display: block;
  overflow: hidden;
  position: relative;
}

.crop-photo {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  img {
    display: block;
    height: 100%;
    width: 100%;
    visibility: hidden;
  }
}

// end images

// main footer
.footer {
  background-color: #303030;
  bottom: 0;
  padding-top: rem(9);
  padding-bottom: rem(9);
  position: absolute;
  text-align: center;
  width: 100%;

  .change-region-flag {
    max-height: 16px;
    max-width: 20px;
  }

  a {
    border-bottom: 1px solid #303030;

    &:hover {
      border-color: $white;
    }
  }

  .grid-x > .cell {
    padding-top: rem(1);
    padding-bottom: rem(1);

    &:first-child {

      @include md-up {
        text-align: left;
      }
    }

    &:last-child {

      @include md-up {
        text-align: right;
      }
    }
  }
}

// end main footer

// form elements
.grid-container label {
  font-weight: 700;
}

.grid-container label span {
  font-size: rem(14);
}

select,
select:focus {
  background-color: $curious-blue;
  border: 0;
}

select {
  background-image: url('../images/sort-select.svg');
  background-position: 100% center;
  background-repeat: no-repeat;
  background-size: 40px;
  background-origin: padding-box;
  font-size: rem(16);
  font-weight: $bold;
  height: auto;
  padding: rem(15) rem(40) rem(15) rem(15);
  position: relative;
}

textarea {
  min-height: 150px;
}

.errorlist {
  list-style-type: none;

  > li {
    position: relative;
    margin: 0 0 1rem 0;
    padding: 1rem;
    border: 1px solid rgba(62, 62, 62, 0.25);
    border-radius: 0;
    background-color: #f7e4e1;
    color: #3e3e3e;
  }
}

.amazon-verify fieldset {
  border: 1px solid $alto-gray;
  background-color: $white;
  padding: rem(20) rem(30);
}

// end form elements

// buttons
.btn,
a.btn,
button,
input[type='submit'],
.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  cursor: pointer;
}

h3 a.btn {
  @include md-up {
    float: right;
  }
}

// end buttons

// tables
thead td,
thead th,
tbody td,
tbody th,
tfoot td,
tfoot th {
  min-width: 178px;

  @include lg-up {
    padding: rem(15) rem(20);
    text-align: center;
  }
}

tbody td {
  &:before {
    content: attr(data-table-head);
    display: block;
    font-size: rem(14);
    font-weight: $bold;

    @include lg-up {
      display: none;
    }
  }
}

thead tr > th:first-child,
thead tr > td:first-child,
tbody tr > th:first-child,
tbody tr > td:first-child,
tfoot tr > th:first-child,
tfoot tr > td:first-child {
  text-align: left;

  &:before {
    margin-right: 0;
  }
}

// end tables

// utilities
.border-box {
  border: 1px solid $mariner-blue;
  padding: rem(20);
  margin-bottom: rem(25);

  p {
    font-size: rem(14);
    font-style: italic;
  }
}

.align-center-middle {
  justify-content: center;
}

// end utilities
