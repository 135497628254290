// toggle collapse module

.mod-toggle-collapse {
  margin-bottom: rem(25);
  
  .collapse-container {
    max-height: 0;
    overflow: hidden;
    @include transition(max-height, .4, ease-out);
    
    &.expand {
      max-height: 350px;
      @include transition(max-height, .4, ease-in);
    }
  }
  
  &.faq {
    
    &.expand > a:after {
      @include rotate(180);
    }
    
    > a {
      font-weight: $bold;
      
      &:after {
        content: '';
        display: inline-block;
        position: relative;
        margin-left: 10px;
        top: -2px;
        border: inset 8px;
        border-bottom: 0;
        border-top-style: solid;
        border-color: $black transparent transparent;
        @include transition(transform, .3, linear);
      }
    }
  }
}