// tabs

.tabs-title {
  
  > a,
  > a:focus,
  > a:hover,
  > a[aria-selected='true'] {
    background: none;
  }
  
  > a {
    color: initial;
    font-size: rem(14);
    font-weight: $semibold;
    text-transform: uppercase;
  }
  
  a:focus {
    outline: 0;
  }
  
  p {
    margin: 0;
    line-height: 1;
  }
  
  > a,
  p {
    font-size: rem(14);
  }
}

.tabs {
  border: 0;
  background: none;
  
  li {
    border: 1px solid $alto-gray;
    
    &:hover,
    &.is-active {
      border-color: $aquamarine-blue;
    }
  }
  
  &.spaced .tabs-title {
    margin-right: rem(15);
    margin-bottom: rem(25)
  }
}

.tabs-categories {
  margin-bottom: rem(25);
  
  i {
    font-size: rem(24);
    
    @include md-up {
      font-size: rem(28);
      margin-bottom: rem(10);
    }
  }
  
  h1,
  h2,
  h3,
  h4 {
    font-size: rem(20);
    margin-bottom: 0;
  }
  
  h1,
  h2,
  h3,
  h4,
  p {
    display: none;
  }
  
  h1,
  h2,
  h3,
  h4 {
    @include md-up {
      display: block;
    }
  }
  
  p {
    text-transform: none;
    font-size: rem(14);
    font-weight: normal;
    
    @include lg-up {
      display: block;
    }
  }
  
  .tabs-title {
    text-align: center;
  }
  
  & > li:last-child {
    border-right: 1px solid $alto-gray;
  }
  
  li {
    border-right: 0;
    width: 25%;
    
    p:last-child {
      margin-bottom: 0;
    }
    
    &:hover,
    &.is-active {
      
      & + li {
        border-left-color: $aquamarine-blue;
      }
    }
  }
  
  li,
  li a {
    @include lg-up {
      height: 160px;
      max-height: 160px;
    }
  }
}

.tabs-content.mod-styled-text {
  
  .tabs-panel {
    padding-top: 0;
    padding-bottom: 0;
  }
}