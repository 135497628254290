// card module

.card {
  border: 1px solid $alto-gray;
  padding: rem(5);
  margin-bottom: 0;
  overflow: visible;
  min-height: 285px;
  max-height: 285px;
  position: relative;

  i {
    font-size: rem(20);
    float: left;
  }

  .card-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
  }

  .ana-store {
    color: $atlantis-green;
  }

  .fa-truck,
  .fa-cart-plus {
    color: $aquamarine-blue;
  }

  .fa-cogs,
  .fa-ban {
    color: $dusty-gray;
  }

  .ana-ship {
    color: $buttercup-orange;
  }

  .fa-industry {
    color: $red-violet;
  }

  .fa-refresh {
    color: $alizarin-crimson;
  }

  .reprint,
  .release-date {
    float: right;
    font-weight: $bold;
    font-size: rem(11);
    line-height: 1;
  }

  .reprint {
    color: #982929;
  }

  .release-date {
    color: $black;
  }

  .image-wrapper {
    min-height: 1px;
  }

  h4,
  p {
    font-size: rem(14);
  }

  h4 {
    font-weight: $bold;
    margin-top: rem(5);
    margin-bottom: 0;
  }

  .btn-wrapper {
    display: flex;
    justify-content: space-between;

    .btn {
      width: 51.5%;
    }
  }

  .btn {
    background: $mine-shaft;
    padding: rem(5);
    margin: 0 -5px -5px;

    i {
      font-size: rem(16);
      float: none;
    }
  }

  &:hover {
    border-color: $aquamarine-blue;
  }
}

.tooltip {
  background: $white;
  border: 1px solid;
  color: $black;
  opacity: 0;
  min-width: 275px;
  text-align: left;
  top: 40px;
  transition: opacity .1s ease-in;
  
  @include lg-up {
    min-width: 300px;
  }
  
  &.fade {
    opacity: 1;
  }
  
  h6 {
    font-weight: $bold;
    margin: -12px -12px 12px -12px;
    padding: 5px 12px;
  }

  &.store-description {
    border-color: $atlantis-green;
  }

  &.shipping-description {
    border-color: $aquamarine-blue;
  }

  &.boat-description {
    border-color: $buttercup-orange;
  }

  &.printer-description {
    border-color: $red-violet;
  }

  &.development-description {
    border-color: $dusty-gray;
  }

  &.reprint-description {
    border-color: $alizarin-crimson;
  }

  &.left:before,
  &.right:before,
  &.top:before,
  &.bottom:before {
    display: none;
  }
}
