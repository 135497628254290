// search header module

.mod-search-header {
  .mod-header {
    margin-bottom: rem(10);
  }

  h1 {
    font-size: rem(24);
    margin-bottom: 0;

    @include md-up {
      font-size: rem(30);
    }
  }

  select {
    margin-bottom: 0;
  }

  .mod-content .grid-x {
    @include md-up {
      margin-left: rem(-5);
      margin-right: rem(-5);
    }

    > .cell {
      margin-bottom: rem(10);

      @include md-up {
        width: calc(50% - 0.625rem);
        margin-left: rem(5);
        margin-right: rem(5);
      }

      @include lg-up {
        width: auto;
      }
    }
  }

  .mod-search-header-left {
    background: $mariner-blue;
    border: 1px solid $mariner-blue;
    display: flex;
    align-items: center;
    padding: rem(20) rem(15);
  }

  .search-bar-container {
    margin-top: rem(15);
    position: relative;

    @include lg-up {
      margin-top: 0;
    }

    input::placeholder {
      font-weight: $regular;
    }

    &:after,
    [type='text'] {
      font-size: rem(16);

      @include md-up {
        font-size: rem(20);
      }
    }

    [type='text'] {
      border: 0;
      border-bottom: 1px solid $alto-gray;
      box-shadow: none;
      display: inline-block;
      height: auto;
      margin-bottom: 0;
      padding: 0;
      width: calc(100% - 1rem);

      @include md-up {
        width: calc(100% - 1.25rem);
      }
    }

    &:after {
      content: '\f002';
      font-family: 'FontAwesome', sans-serif;
      position: absolute;
      right: 0;
    }
  }

  .mod-search-header-right {
    .fieldset {
      border: 1px solid $curious-blue;
      margin-top: 0;
      margin-bottom: 0;
      padding: rem(25) rem(15);

      @include lg-up {
        padding: 0 rem(15);
        height: 85px;
        max-height: 85px;
      }

      .grid-x {
        @include lg-up {
          align-items: center;
          height: 100%;
        }

        > .cell > label:last-child {
          @include lg-up {
            margin-right: 0;
          }
        }
      }
    }

    label {
      font-size: rem(16);
      font-weight: $bold;
      margin-left: 0;
      width: 100%;
      vertical-align: top;

      @include md-up {
        width: auto;
        max-width: 200px;
      }

      i {
        font-size: rem(18);
      }

      span {
        color: $dusty-gray;
        display: block;
        font-weight: $medium;
        font-size: rem(16);
        line-height: 1.4;
        margin-top: rem(-5);
      }

      @include md-up {
        margin-right: rem(5);
      }
    }

    // sass-lint:disable
    [type='radio'] {
      &:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
      }

      &:checked + label:after {
        opacity: 1;
        transform: scale(1);
      }

      &:checked,
      &:not(:checked) {
        left: -9999px;
        position: absolute;

        & + label {
          cursor: pointer;
          position: relative;
          padding-left: rem(24);

          &:before {
            content: '';
            border: 2px solid $black;
            position: absolute;
            height: 15px;
            width: 16px;
            left: 0;
            top: 6px;
          }

          &:after {
            content: '\f00c';
            font-family: 'FontAwesome', sans-serif;
            font-size: rem(20);
            position: absolute;
            left: 0;
            top: -6px;
          }
        }
      }
    }
  }
}
