// related products module

.mod-related-products {
  position: relative;

  h3,
  h4 {
    margin-bottom: 0;
  }

  .mod-header {
    position: absolute;
    max-width: 255px;
    padding: rem(10) rem(15);
    top: -25px;
    width: 100%;

    @include md-up {
      max-width: 309px;
      padding: rem(24) rem(20);
      top: -38px;
    }
  }

  a.more-products-link {
    left: 319px;
    top: 20px;
    font-weight: $bold;
    color: $mariner-blue;
    margin-top: -5px;
    margin-bottom: rem(20);
    display: block;

    @include md-up {
      position: absolute;
      margin-bottom: 0;
      display: block;
    }
  }

  .mod-content {
    border: 1px solid $dusty-gray;
    margin-top: rem(30);
    padding: rem(40) rem(17) rem(17);

    @include md-up {
      margin-top: rem(60);
      padding: rem(90) rem(17) rem(17);
    }

    .text-wrapper {
      margin-top: rem(30);
    }

    h4,
    p {
      font-size: rem(14);
    }
  }
}
