// article list module

.mod-article-list {
  position: relative;
  @include transition(background-position, 0.2, ease-in-out);

  .mod-header,
  .mod-content {
    padding-left: rem(10);

    @include sm-up {
      padding-left: rem(20);
    }
  }

  .mod-header {
    padding-top: rem(20);
    padding-bottom: rem(20);

    h3 {
      margin-bottom: 0;
    }
  }

  ul {
    margin: 0;
    position: relative;
  }

  .headline-wrapper {
    h4 {
      font-size: rem(14);
      font-weight: $bold;
    }

    p {
      font-size: rem(12);
      margin: 0;
    }
  }
}

.article-list-mbibg.mod-article-list,
.studio.mod-article-list {
  @include sm-up {
    background-position-x: 59.5%;
    background-position-y: -673px;
  }

  @include md-up {
    background-position-x: 53%;
  }

  @include lg-up {
    background-position-x: 35.5%;
  }
}

.medium-6 {
  .article-list-mbibg.mod-article-list,
  .studio.mod-article-list {
    background-size: 1150px;
    background-position-y: -388px;
    background-position-x: 60%;

    @include sm-up {
      background-position-x: 50%;
    }

    @include md-up {
      background-position-x: 59.5%;
    }

    @include lg-up {
      background-position-x: 47.6%;
    }
  }

  .mod-article-list {
    .mod-header,
    .mod-content {
      @include md-up {
        padding-left: rem(10);
      }

      @include lg-up {
        padding-left: rem(20);
      }
    }

    .mod-header h3 {
      @include md-up {
        font-size: rem(24);
      }

      @include lg-up {
        font-size: rem(30);
      }
    }

    .headline-wrapper {
      h4 {
        @include lg-up {
          font-size: rem(16);
        }
      }

      p {
        @include lg-up {
          font-size: rem(14);
        }
      }
    }
  }
}
