// navigation

// dropdown indicator
.mobile-nav .dropdown-link:after,
.desktop-nav-container .dropdown.menu > li.is-dropdown-submenu-parent a:after {
  border-color: $dusty-gray transparent transparent;
}

// dropdown indicator
.mobile-nav .active > .dropdown-link:after,
.desktop-nav-container .dropdown.menu > li.is-dropdown-submenu-parent.active a:after {
  border-color: $black transparent transparent;
}

// dropdown indicator
.mobile-nav .dropdown-link:hover:after,
.mobile-nav .active > .dropdown-link:hover:after,
.mobile-nav .dropdown-link.dropdown-active:after,
.mobile-nav .dropdown-link.dropdown-active:hover:after,
.mobile-nav .active > .dropdown-link.dropdown-active:after,
.mobile-nav .active > .dropdown-link.dropdown-active:hover:after,
.desktop-nav-container .dropdown.menu > li.is-dropdown-submenu-parent.is-active a:after,
.desktop-nav-container .dropdown.menu > li.is-dropdown-submenu-parent.active a:hover:after {
  border-color: $torea-bay-blue transparent transparent;
}

.active a {
  border-bottom: 2px solid $torea-bay-blue;
}

.active .active a {
  border-bottom: none;
}

nav {
  padding-top: rem(15);
  padding-bottom: rem(15);

  .top-bar,
  .top-bar ul {
    background: none;
  }

  .top-bar {
    padding: 0;
    z-index: 99;
  }

  .title-bar {
    padding: 0;
    background: none;
  }

  .active > a {
    font-weight: $bold;
  }

  .top-bar-right .menu {

    a,
    .button {
      display: initial;
    }
  }

  .menu .active > a {
    background: none;
  }
}

// mobile navigation
.mobile-nav-container {
  @include lg-up {
    display: none;
  }

  .top-bar .top-bar-left {
    flex: 1 0 auto;
  }

  .top-bar .top-bar-right {
    flex: 0 1 auto;
  }

  .top-bar-right .menu a {
    padding-left: rem(10);
    padding-right: rem(10);
  }

  .top-bar-left {
    max-width: 50px;
  }
}

.mobile-nav,
.nested {
  max-height: 0;
  overflow: hidden;
}

.nested {
  @include transition(max-height, 0.7, ease-in-out);
}

.mobile-nav {
  @include transition(max-height, 0.5, ease-in-out);

  &.expand {
    max-height: 450px;
  }

  &.expand2 {
    max-height: 650px;
  }

  .nested.expand {
    max-height: 300px;
  }

  &.menu {

    li {
      margin: auto;
    }

    a {
      display: inline-block;
      font-size: rem(16);
      padding: rem(40) rem(20) rem(5);
      border-bottom: 2px solid rgba(149, 149, 149, .4);
    }

    .active a {
      border-bottom: 2px solid $torea-bay-blue;
    }

    li:first-child {
      padding-top: rem(5);
    }

    li:last-child {
      padding-bottom: rem(25);
    }
  }

  .nested {
    margin-left: 0;
  }

  .dropdown-link {
    position: relative;

    &.dropdown-active:after {
      @include rotate(180);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      border: inset 6px;
      border-bottom: 0;
      border-top-style: solid;
      border-color: $dusty-gray transparent transparent;
      right: rem(5);
      left: auto;
      @include transition(transform, 0.3, ease-in-out);
    }
  }
}

.hamburger-button {
  margin-top: rem(2);
  width: 35px;
  height: 30px;
  position: relative;
  float: right;
  cursor: pointer;
  @include rotate(0);

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: $gun-powder-gray-blue;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    @include rotate(0);

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: rem(8);
    }

    &:nth-child(3) {
      top: rem(16);
    }

    &:nth-child(4) {
      top: rem(24);
    }
  }

  &.open span {

    &:nth-child(1) {
      top: rem(8);
      width: 0;
      opacity: 0;
      left: 50%;
    }

    &:nth-child(2) {
      top: rem(16);
      @include rotate(45);
    }

    &:nth-child(3) {
      top: rem(16);
      @include rotate(-45);
    }

    &:nth-child(4) {
      top: rem(8);
      width: 0;
      opacity: 0;
      left: 50%;
    }
  }
}

// end mobile navigation

// desktop navigation
.login-btn {
  border: 4px solid $alto-gray;
  border-top: 0;
  font-size: rem(14);
  font-weight: $medium;
  padding: rem(8) rem(12);
  position: relative;

  &:hover {
    border-color: $aquamarine-blue;
  }

  &:after {
    background-color: $alto-gray;
    content: '';
    cursor: pointer;
    display: table;
    height: 36px;
    position: absolute;
    right: rem(-8);
    top: 0;
    width: 50%;
    z-index: -1;
    @include skew(-8);
  }
}

.desktop-nav-container {
  display: none;

  @include lg-up {
    display: block;
  }

  .logo-wrapper {
    max-width: 130px;
    min-width: 130px;
  }

  .menu {
    align-items: center;
  }

  .is-dropdown-submenu {
    border: 0;
    min-width: 160px;
  }

  .top-bar {
    position: relative;
  }

  .top-bar-right {
    position: absolute;
    right: 0;
    top: rem(-11);
  }

  .dropdown.menu {

    > li.opens-inner > .is-dropdown-submenu,
    > li.opens-left > .is-dropdown-submenu,
    > li.opens-right > .is-dropdown-submenu {
      right: auto;
      left: auto;
    }

    > li {
      padding-left: rem(40);

      &.logo-wrapper,
      &.logo-wrapper a {
        padding: 0;
      }

      > a {
        padding-left: rem(15);
        padding-bottom: rem(5);
        padding-right: rem(15);
      }

      .dropdown-link {
        padding-right: rem(34);
      }

      &.is-dropdown-submenu-parent > a:after {
        margin-top: 0;
        right: rem(15);
      }
    }
  }
}

// end desktop navigation
