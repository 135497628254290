.bxslider {
  list-style-type: none;
  overflow: hidden;
  height: 322px!important;
}

.bx-wrapper {
  
  .bx-controls-direction a {
    text-indent: 0;
  }
  
  .bx-next,
  .bx-prev {
    background: transparent;
    font-size: rem(20);
    text-align: center;
    
    &:before {
      font-family: 'FontAwesome';
      font-size: rem(20);
      color: $white;
      opacity: 0.6;
      @include transition(opacity, .3, ease-in-out);
    }
    
    &:hover:before {
      opacity: 1;
    }
    
  }
  
  .bx-next:before {
    content: '\f054';
  }
  
  .bx-prev:before {
    content: '\f053';
  }
}