// freelance text module

.mod-inside-block-list.mod-freelance-text {
  position: relative;

  .mod-header {
    border: 1px solid $mariner-blue;
    border-left: 0;
    border-right: 0;

    @include md-up {
      padding: 20px 0 65px;
    }
  }

  .mod-content {
    margin-top: -45px;
    padding: 0;
  }

  li {
    border: 1px solid $black;

    .btn-wrapper {
      @include md-up {
        max-width: 155px;
      }
    }
  }
}
