// blog

.blog-header {
  border-bottom: 2px solid $alto-gray;

  .blog-visual {
    overflow: hidden;

    .crop-photo img {
      height: 350px;
    }
  }

  .blog-titlelead {
    margin-top: 10px;
    line-height: 1.2;

    time {
      font-size: rem(10);

      @include sm-up {
        font-size: rem(12);
      }
    }

    h1 {
      font-size: rem(24);
      font-weight: $bold;
      margin-bottom: 4px;

      @include sm-up {
        font-size: rem(28);
      }
    }
  }
}

.blog-content {
  margin-top: rem(20);

  h2 {

    @include sm-up {
      font-size: rem(20);
    }
  }

  .blog-back {
    margin-top: 30px;

    .btn {
      font-size: rem(18);
      text-align: left;
      position: relative;
      float: left;
      padding-top: 10px;
      padding-bottom: 25px;
      padding-left: 27px;
      width: 100%;
      max-width: 270px;

      @include sm-up {
        font-size: rem(21);
        max-width: 366px;
      }

      &:after {
        content: '';
        display: table;
        position: absolute;
        background: $off-white;
        width: 150%;
        height: 30px;
        left: rem(-20);
        top: rem(49);
        @include rotate(-3);

        @include sm-up {
          top: 51px;
        }

        @include lg-up {
          width: 125%;
        }
      }

      i {
        font-size: rem(26);
        vertical-align: top;

        @include sm-up {
          font-size: rem(30);
        }
      }
    }
  }
}
