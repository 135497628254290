// media grid module

.mod-media-grid {
  
  ul {
    width: 100%;
  }
  
  .mod-content {
    margin-left: rem(-5);
    margin-right: rem(-5);
  }
  
  .list {
    
    &.media > li {
      padding-right: rem(5);
      padding-left: rem(5);
    }
    
    > li {
      margin-bottom: rem(10);
    }
  }
}

.studio-logos {
  
  .list.media > li {
    float: none;
    display: inline-block;
    padding: 0 rem(10) rem(30);
    margin-bottom: 0;
  }
  
  img {
    max-width: 180px;
    max-height: 116px;
  }
}

.catalog {
  
  .image-wrapper {
    max-width: 163px;
  }
  
  .list.media {
    margin-right: 0;
    margin-left: 0;
  }
}